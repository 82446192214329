.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #232323;
}

h1 {
  margin: 0;
  padding: 20px;
  color: #23ABE1;
  background: white;
  border-bottom: 3px solid #23ABE1;
}

h2 {
  color: white;
  margin: 0;
  padding-top: 15px;
}

p {
  font-size: 22px;
  color: white;
  padding: 15px;
  margin: 0;
}

img {
  height: 80%;
}

.square {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border: 2px solid #23ABE1;
  box-sizing: border-box;
  margin: 5px;
  height: 100px;
  width: 100px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 400px;
  margin: 0 auto;
}

button {
  background: #23ABE1;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px auto;
  font-weight: bold;
  transition: .5s;
}

button:hover {
  background: white;
  color: #232323;
  border: 2px solid #23ABE1;
  padding: 13px 23px;
}